import React from "react"

import { useTranslation } from "react-i18next"
import { Card, CardBody } from "../molecules/Card"
import { faArrowDownToBracket } from "@fortawesome/sharp-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FluidHeadline from "../atoms/FluidHeadline"

const ProductDetailsDownloads = ({ downloads, safetyDatasheets }) => {
  const { t } = useTranslation()

  const typeLookup = {}
  const labeledDownloads = []

  // thx DESIGN: we have no usefull download names, so generate ersatzteilliste 1, ersatzteilliste 2 etc if more than one
  downloads.forEach((d) => {
    const newD = d
    if (typeLookup[newD.label]) {
      typeLookup[newD.label] = parseInt(typeLookup[newD.label]) + 1
    } else {
      typeLookup[newD.label] = 1
    }
    newD.countedLabel = newD.label + " " + typeLookup[newD.label]
    labeledDownloads.push(newD)
  })

  // thx PIM: german safetysheets are doubled, so dedup here by filename
  const filteredSafetyDatasheets = safetyDatasheets.filter(
    (obj1, i, arr) =>
      arr.findIndex((obj2) => obj2.fileName === obj1.fileName) === i
  )
  const sdsCount = filteredSafetyDatasheets.length

  return (
    <section className="py-block" id="product-downloads">
      <FluidHeadline headline={t("generic.products.documents_download")} />
      <div className="flex flex-row flex-wrap mt-4 -mb-4 md:mt-8 md:-mb-6 -mx-grid">
        {labeledDownloads?.length > 0 &&
          labeledDownloads?.map((download, index) => (
            <div
              className="w-full mb-4 md:mb-6 lg:w-1/2 xl:w-1/3 px-grid"
              key={`download-${index}`}
            >
              <Card className="block w-full" link={download.link}>
                <CardBody>
                  <span className="flex flex-row font-bold">
                    <span className="mr-2">
                      {typeLookup[download.label] > 1
                        ? download.countedLabel
                        : download.label}
                    </span>
                    <FontAwesomeIcon
                      icon={faArrowDownToBracket}
                      size="1x"
                      className="self-center inline-block h-4 ml-auto"
                    />
                  </span>
                  <span className="inline-block mt-2 uppercase">
                    {download.fileType}
                  </span>
                  <span className="inline-block mt-2 ml-1 uppercase">
                    – {(download.fileSize / 1000000).toFixed(2)} MB
                  </span>
                </CardBody>
              </Card>
            </div>
          ))}
        {filteredSafetyDatasheets?.length > 0 &&
          filteredSafetyDatasheets?.map((download, index) => (
            <div
              className="w-full mb-6 md:w-1/2 lg:w-1/3 px-grid"
              key={`sheet-${index}`}
            >
              <Card className="block w-full" link={download.link}>
                <CardBody>
                  <span className="flex flex-row font-bold">
                    <span className="mr-2">
                      {t(`safety_datasheets.title_single`) +
                        (sdsCount > 1 ? " " + (index + 1) : "")}
                    </span>
                    <FontAwesomeIcon
                      icon={faArrowDownToBracket}
                      size="1x"
                      className="self-center inline-block h-4 ml-auto"
                    />
                  </span>
                  {download.fileType ? (
                    <span className="inline-block mt-2 uppercase">
                      {download.fileType}
                    </span>
                  ) : (
                    <span className="block mt-2 uppercase">
                      {download.fileName.split(".")[1]}
                    </span>
                  )}
                  {download.fileSize && (
                    <span className="inline-block mt-2 ml-1 uppercase">
                      – {(download.fileSize / 1000000).toFixed(2)} MB
                    </span>
                  )}
                </CardBody>
              </Card>
            </div>
          ))}
      </div>
    </section>
  )
}

export default ProductDetailsDownloads
