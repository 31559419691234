import React from "react"
import Blocks from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/blocks/_Blocks"

const ProductDetailsSbBlocks = ({ components }) => {
  return (
    <>
      {components?.length > 0 &&
        components.map((content) =>
          React.createElement(Blocks(content.component), {
            key: content._uid,
            block: content,
            type: content.component,
          })
        )}
    </>
  )
}

export default ProductDetailsSbBlocks
