import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
// ---- SWIPER -----
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper/modules"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

// ---- FONT-AWESOME ICONS -----
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/sharp-light-svg-icons"
// -------
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import CustomCloudinaryVideoPlayer from "./CustomCloudinaryVideoPlayer"
import MemoizedComponentWrapper from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"

const ProductMediaSwiperItem = ({
  item,
  idx,
  className = "",
  variant = "slide",
}) => {
  switch (item.type) {
    case "noimage":
      return (
        <div className={`${className}`}>
          <Image
            className="object-contain w-auto h-full"
            image={{ filename: item.url }}
            imageWidth="auto"
            lazyLoad={false}
          />
        </div>
      )
    case "image":
      return (
        <div className={`${className}`}>
          <Image
            gravity="center"
            className="object-contain w-auto h-full"
            image={item.url}
            imageWidth="auto"
            lazyLoad={false}
          />
        </div>
      )
    case "video":
      return (
        <div className={`${className}`}>
          <div className="ro-video-wrapper">
            <div className="ro-video-scaler">
              <div className="ro-video-player">
                <CustomCloudinaryVideoPlayer videoFilename={item.url} />
              </div>
            </div>
          </div>
        </div>
      )
    default:
      return null
  }
}

const ProductMediaModalSwiper = ({ mediaItems, startIdx }) => {
  const [swiperInstance, setSwiperInstance] = useState(null)
  const [onFirstSlide, setOnFirstSlide] = useState(true)
  const [onLastSlide, setOnLastSlide] = useState(false)
  const [currentSwiperId, setCurrentSwiperId] = useState("")

  // this is all needed due to swiper totally bugging inside a modal, kind of
  const handlePrev = () => {
    swiperInstance.slidePrev()
  }
  const handleNext = () => {
    swiperInstance.slideNext()
  }

  const onActiveIndexChange = (sw) => {
    if (sw.activeIndex === 0) {
      setOnFirstSlide(true)
      setOnLastSlide(false)
    } else if (sw.activeIndex === mediaItems.length - 1) {
      setOnFirstSlide(false)
      setOnLastSlide(true)
    } else {
      setOnFirstSlide(false)
      setOnLastSlide(false)
    }
  }

  useEffect(() => {
    if (swiperInstance) {
      setCurrentSwiperId(swiperInstance.slidesEl.id)
      swiperInstance.update()
      swiperInstance.slideTo(startIdx)
    }
    return () => {
      if (swiperInstance) {
        swiperInstance.update()
      }
    }
  }, [swiperInstance])

  return (
    <div className="relative w-full h-screen">
      <div className="h-screen px-0 py-8 md:p-8">
        <button
          onClick={(e) => handlePrev()}
          aria-controls={currentSwiperId}
          aria-label="Previous slide"
          aria-disabled={onFirstSlide ? true : false}
          {...(onFirstSlide ? { disabled: true } : {})}
          className={`hidden h-12 w-12 text-ro-gray-900 absolute transform -translate-y-1/2 left-0 top-1/2 hover:bg-ro-gray-75 active:bg-ro-gray-200 md:block ${
            onFirstSlide ? " opacity-25 pointer-events-none" : ""
          }`}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="1x" />
        </button>
        <button
          onClick={(e) => handleNext()}
          aria-controls={currentSwiperId}
          aria-label="Next slide"
          aria-disabled={onLastSlide ? true : false}
          {...(onLastSlide ? { disabled: true } : {})}
          className={`hidden h-12 w-12 text-ro-gray-900 absolute transform -translate-y-1/2 right-0 top-1/2 hover:bg-ro-gray-75 active:bg-ro-gray-200 md:block ${
            onLastSlide ? " opacity-25 pointer-events-none" : ""
          }`}
        >
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </button>
        <Swiper
          id="product-inModal-media-swiper"
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          onActiveIndexChange={onActiveIndexChange}
          onSwiper={setSwiperInstance}
          initialSlide={startIdx}
          pagination={{
            renderBullet: function (index, className) {
              return `<button class="flex justify-center items-center ${className}" role="button" aria-label="Got to slide ${
                index + 1
              }"><span class="inline-block rounded-full w-2 h-2"></span></button>`
            },
            clickable: true,
            el: "#product-inModal-media-swiper-pagination",
            bulletClass: "w-6 h-6 custom-swiper-bullet",
            bulletActiveClass: "active",
          }}
        >
          {mediaItems.map((item, i) => (
            <SwiperSlide
              key={`main-swiper-inModal-slide-${i}`}
              className="h-[calc(100vh-4rem)]"
            >
              <MemoizedComponentWrapper>
                <ProductMediaSwiperItem
                  className="h-[calc(100vh-4rem)] flex items-center justify-center"
                  item={item}
                  idx={i}
                />
              </MemoizedComponentWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          id="product-inModal-media-swiper-pagination"
          className="flex justify-center h-12 items-center absolute !bottom-0 left-0 right-0"
        ></div>
      </div>
    </div>
  )
}

export default ProductMediaModalSwiper
