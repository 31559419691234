import React, { useState, useRef, useEffect } from "react"

import { useTranslation } from "react-i18next"
import ShowMoreLinkButton from "../atoms/ShowMoreLinkButton"
import FluidHeadline from "../atoms/FluidHeadline"

const SINGLE_LINE_HEIGHT = 24
const LINES_TO_FOLD_FROM = 6 // when u change this value, remember to change the line-clamp class accordingly! in line 40
const DETECT_DESCRIPTION_HEIGHT = SINGLE_LINE_HEIGHT * LINES_TO_FOLD_FROM

const ProductDetailsDescription = ({ text }) => {
  const { t } = useTranslation()
  const [isFolded, setIsFolded] = useState(false) // init as NOT FOLDED
  const [showFoldButton, setShowFoldButton] = useState(false)
  const refText = useRef()

  useEffect(() => {
    if (
      refText?.current &&
      refText?.current?.offsetHeight > DETECT_DESCRIPTION_HEIGHT
    ) {
      setShowFoldButton(true) // show Button when height > DETECT_DESCRIPTION_HEIGHT
      setIsFolded(true) // set the fold state to true
    }
  }, [refText])

  const toggleShowMoreState = (state) => {
    setIsFolded(!state) // invert the bool of "ShowMore"
  }

  return (
    <section className="py-block" id="product-detail-description">
      <FluidHeadline headline={t("generic.products.product_description")} />
      <div className="w-full mt-2 md:mt-6 xl:w-2/3">
        <div
          ref={refText}
          className={`prose max-w-none ${isFolded ? `line-clamp-6` : ""}`}
          dangerouslySetInnerHTML={{
            __html: `<p>${text?.split("\n\n").join("</p><p>")}</p>`,
          }}
        ></div>
        {showFoldButton && (
          <ShowMoreLinkButton
            className="mt-4"
            onStateChange={toggleShowMoreState}
          />
        )}
      </div>
    </section>
  )
}

export default ProductDetailsDescription
