import { useEffect, useState } from "react"
import axios from "axios"

const useNexmartRetailers = (sku) => {
  const [retailers, setRetailers] = useState(null)
  const [hasRetailers, setHasRetailers] = useState(undefined)
  useEffect(() => {
    axios
      .get(process.env.NEXMART_API_URL, {
        auth: {
          username: process.env.NEXMART_USERNAME,
          password: process.env.NEXMART_PASSWORD,
        },
        params: {
          services: "online",
          supplier: process.env.NEXMART_SUPPLIER,
          "pid[1]": sku,
        },
      })
      .then((res) => {
        setRetailers(res.data.retailers)
        if (res.data.retailers && res.data.retailers.length > 0) {
          setHasRetailers(true)
        } else {
          setHasRetailers(false)
        }
      })

    return () => {
      setRetailers(null)
    }
  }, [sku])
  return [hasRetailers, retailers]
}

export default useNexmartRetailers
