import React from "react"
import { useTranslation } from "react-i18next"
import FluidHeadline from "../atoms/FluidHeadline"

const ProductDetailsDeliveryScope = ({ headline, deliveryScope }) => {
  const { t } = useTranslation()
  return (
    <section className="py-block" id="delivery-scope">
      <FluidHeadline headline={t("generic.products.delivery_scope")} />
      <div className="mt-2 prose md:mt-6">
        <ul
          dangerouslySetInnerHTML={{
            __html: `<li>${deliveryScope
              ?.replace(/\n\n/g, "\n")
              .replace(/\n/g, "</li><li>")}</li>`.replace(
              /<li>(\s)*<\/li>/g,
              ""
            ),
          }}
        ></ul>
      </div>
    </section>
  )
}

export default ProductDetailsDeliveryScope
