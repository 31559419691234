import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ShowMoreLinkButton from "../atoms/ShowMoreLinkButton"
import FluidHeadline from "../atoms/FluidHeadline"

const ProductDetailsTechnicalData = ({ headline, techContent, baseData }) => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const [showMoreState, setShowMoreState] = useState(false)
  const toggleShowMoreState = (state) => {
    setShowMoreState(state)
  }

  return (
    <section className="py-block" id="technical-data">
      <FluidHeadline headline={t("generic.products.technical_data")} />
      <div className="w-full mt-4 md:mt-8 lg:w-2/3">
        <div className="py-3 -mx-4 font-bold px-grid bg-ro-gray-50 md:mx-0">
          {t("generic.products.most_important_facts")}
        </div>
        <table className="w-auto -mx-4 table-auto md:mx-0 md:w-full">
          <tbody>
            {techContent?.length > 0 &&
              techContent.map(
                (item, index) =>
                  item.value.Value !== "0,000 X 0,000 X 0,000 " &&
                  item.value.Value !== "0,000 " && (
                    <tr key={index} className="border-b border-ro-gray-100">
                      <td className="w-1/2 py-3 px-grid">
                        {currentLang === "de"
                          ? item.label
                          : item.translated_label}
                      </td>
                      <td className="w-1/2 py-3 px-grid">
                        {!Array.isArray(item.value.Value)
                          ? item.value.Value
                          : item.value.Value.join(", ")}
                      </td>
                    </tr>
                  )
              )}
          </tbody>
        </table>
        {showMoreState && (
          <>
            <div className="py-3 mt-8 -mx-4 font-bold px-grid bg-ro-gray-50 md:mx-0">
              {t("generic.products.generic_information")}
            </div>
            <table className="w-auto -mx-4 table-auto md:mx-0 md:w-full">
              <tbody>
                {baseData?.length > 0 &&
                  baseData.map(
                    (item, index) =>
                      item.value.Value !== "0,000 X 0,000 X 0,000 " &&
                      item.value.Value !== "0,000 " && (
                        <tr key={index} className="border-b border-ro-gray-100">
                          <td className="w-1/2 py-3 px-grid">
                            {currentLang === "de"
                              ? item.label
                              : item.translated_label}
                          </td>
                          <td className="w-1/2 py-3 px-grid">
                            {item.value.Value}
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </table>
          </>
        )}
        <ShowMoreLinkButton
          className="mt-6"
          onStateChange={toggleShowMoreState}
        />
      </div>
    </section>
  )
}

export default ProductDetailsTechnicalData
