import React from "react"
import { useTranslation } from "react-i18next"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import FluidHeadline from "../atoms/FluidHeadline"

const ProductDetailsFunctions = ({ functions }) => {
  const { t } = useTranslation()

  return (
    <section className="py-block" id="product-functions">
      <FluidHeadline headline={t("generic.products.functions_features")} />
      <div className="flex flex-row flex-wrap mt-4 -mb-4 md:mt-8 md:-mb-8 -mx-grid">
        {functions?.length > 0 &&
          functions?.map((item, index) => (
            <div
              className="w-full mb-4 md:mb-8 lg:w-1/2 xl:w-1/3 px-grid"
              key={`function-${index}`}
            >
              <div className="p-4 bg-ro-gray-50">
                <div className="h-10">
                  <Image
                    className="w-auto h-full"
                    imageHeight={40}
                    imageWidth="auto"
                    image={item.src}
                  />
                </div>
                <div className="mt-6">
                  {item.title?.length > 0 && (
                    <span className="text-lg font-bold line-clamp-3">
                      {item.title}
                    </span>
                  )}
                  {/* {item.description?.length > 0 && (
                    <span className="mt-2 text-gray-600 line-clamp-4">
                      {item.description}
                    </span>
                  )} */}
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  )
}

export default ProductDetailsFunctions
