import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import DividerBlock from "../../themes/gatsby-theme-rothenberger/src/components/blocks/DividerBlock"
import { switchedObjectNameOrLabel } from "gatsby-theme-rothenberger/src/utilities/product-data-utils"

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import ProductOverview from "gatsby-theme-rothenberger/src/components/modules/ProductOverview"

import ProductDetailsGrid from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsGrid"
import ProductDetailsDescription from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsDescription"
import ProductDetailsFunctions from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsFunctions"
import ProductDetailsDeliveryScope from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsDeliveryScope"
import ProductDetailsTechnicalData from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsTechnicalData"
import ProductDetailsDownloads from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsDownloads"
import ProductVariantCardsSlider from "gatsby-theme-rothenberger/src/components/modules/ProductVariantCardsSlider"
import ProductDetailsSbBlocks from "gatsby-theme-rothenberger/src/components/modules/ProductDetailsSbBlocks"

const VariantsTemplate = ({ data }) => {
  let entry = data.entry
  entry.name = switchedObjectNameOrLabel(entry)
  const group = data.group
  const line = data.line
  const area = data.area
  const sbPage = data.storyblokPage
  const sbPageContent =
    sbPage && sbPage.content ? JSON.parse(sbPage.content) : null
  const crossSellingVariants = data.crossSellingVariants.edges
  const similarProductVariants = data.similarProductVariants.edges
  const accesoriesProductsVariants = data.accesoriesProductsVariants.edges
  const deliveryScope =
    entry.properties.deliveryScope !== "undefined"
      ? entry.properties.deliveryScope
      : entry.properties.salesText

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
    { link: line.fullSlug, link_text: line.name },
    { link: group.fullSlug, link_text: group.name },
  ]

  const variants = data.variants.edges
  const description = entry.properties.description
  const teaser = entry.properties.teaser
  const features = entry.properties.features
  const functions = entry.properties.featureIcons
  const techContent = JSON.parse(entry.properties.techContent)
  const baseData = JSON.parse(entry.properties.baseData)
  const documents = entry.properties.documents
  let safetyDatasheets = JSON.parse(entry.properties.safetyDatasheets)
  const crossSellingProductItems = crossSellingVariants?.map((v) => {
    return {
      title: switchedObjectNameOrLabel(v.node),
      article_number: v.node.externalKey,
      link: v.node.fullSlug,
      image: { filename: v.node.properties.mainImage },
    }
  })
  const similarProductItems = similarProductVariants?.map((v) => {
    return {
      title: switchedObjectNameOrLabel(v.node),
      article_number: v.node.externalKey,
      link: v.node.fullSlug,
      image: { filename: v.node.properties.mainImage },
    }
  })
  const accesoriesProductItems = accesoriesProductsVariants?.map((v) => {
    return {
      title: switchedObjectNameOrLabel(v.node),
      article_number: v.node.externalKey,
      link: v.node.fullSlug,
      image: { filename: v.node.properties.mainImage },
    }
  })
  // const media = JSON.parse(entry.properties.media)

  if (process.env.GATSBY_GLOBAL_PATH_PREFIX === "de") {
    safetyDatasheets = safetyDatasheets.filter(
      (sdb) => sdb.countryCode === "de"
    )
  }

  const variantItems = variants.map((variant) => {
    return {
      active: entry.ID === variant.node.ID,
      slug: variant.node.fullSlug,
      headline: switchedObjectNameOrLabel(variant.node),
      text: variant.node.externalKey,
      image: variant.node.properties.mainImage,
      accessories:
        variant.node.properties.deliveryScope !== "undefined"
          ? variant.node.properties.deliveryScope
          : variant.node.properties.salesText,
    }
  })
  // active item is always the first item
  // .sort((x, y) => {
  //   return x.active === y.active ? 0 : x.active ? -1 : 1
  // })

  return (
    <Layout language={entry.language}>
      <SEO
        title={`${entry.name} | ${group.name}`}
        description={`${entry.properties?.description.replace(/\n/, "")}`}
        og_image={entry.properties?.mainImage}
        language={entry.language}
      />
      <ProductOverview
        pimUid={entry.ID}
        breadcrumbs={breadcrumbs}
        headline={switchedObjectNameOrLabel(entry)}
        teaser={teaser}
        description={description}
        variants={variantItems}
        choosen_variant={entry.name}
        choosen_sku={entry.externalKey}
        features={features}
        product_images={[
          entry.properties.mainImage,
          ...entry.properties.images,
        ]}
        product_videos={entry.properties.productVideoFilenames}
        product_thrixty_views={entry.properties.productThrixtyFilenames}
        product_orbitvu_embed={entry.properties.orbitVuEmbedCode}
      />

      <DividerBlock block={{ className: "hidden md:block" }} />
      <ProductDetailsGrid
        hasProductDescription={description?.length > 0}
        hasProductFunctions={functions?.length > 0}
        hasDeliveryScope={deliveryScope?.length > 0}
        hasTechnicalData={techContent?.length > 0 || baseData?.length > 0}
        hasDownloads={documents?.length > 0 || safetyDatasheets?.length > 0}
      >
        {description?.length > 0 && (
          <ProductDetailsDescription text={description} />
        )}
        {functions?.length > 0 && (
          <>
            <DividerBlock block={{ className: "px-0" }} />
            <ProductDetailsFunctions functions={functions} />
          </>
        )}
        {deliveryScope?.length > 0 && (
          <>
            <DividerBlock block={{ className: "px-0" }} />
            <ProductDetailsDeliveryScope
              headline="Lieferumfang"
              deliveryScope={deliveryScope}
            />
          </>
        )}
        {(techContent?.length > 0 || baseData?.length > 0) && (
          <>
            <DividerBlock block={{ className: "px-0" }} />
            <ProductDetailsTechnicalData
              techContent={techContent}
              baseData={baseData}
            />
          </>
        )}
        {(documents?.length > 0 || safetyDatasheets?.length > 0) && (
          <>
            <DividerBlock block={{ className: "px-0" }} />
            <ProductDetailsDownloads
              downloads={documents}
              safetyDatasheets={safetyDatasheets}
            />
          </>
        )}
      </ProductDetailsGrid>
      {crossSellingProductItems?.length > 0 && (
        <>
          <DividerBlock block={{}} />
          <ProductVariantCardsSlider
            uid="cross_selling_products"
            headlineKey="generic.products.cross_selling_products"
            card_items={crossSellingProductItems}
          />
        </>
      )}
      {similarProductItems?.length > 0 && (
        <>
          <DividerBlock block={{}} />
          <ProductVariantCardsSlider
            uid="similar_products"
            headlineKey="generic.products.similar_products"
            card_items={similarProductItems}
          />
        </>
      )}
      {accesoriesProductItems?.length > 0 && (
        <>
          <DividerBlock block={{}} />
          <ProductVariantCardsSlider
            uid="accessories_products"
            headlineKey="generic.products.accessories_products"
            card_items={accesoriesProductItems}
          />
        </>
      )}
      {sbPageContent && sbPageContent.body && (
        <ProductDetailsSbBlocks components={sbPageContent.body} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query VariantQuery(
    $id: String
    $parentId: String!
    $locale: String
    $groupId: String
    $lineId: String
    $areaId: String
    $crossSellingVariantIDs: [String]
    $similarProductsVariantIDs: [String]
    $accesoriesProductsVariantIDs: [String]
  ) {
    entry: contentServVariant(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      marketingLabel
      externalKey
      ID
      language
      rootPage {
        name
        fullSlug
      }
      properties {
        media
        description
        teaser
        mainImage
        images
        featureIcons {
          title
          description
          src
        }
        features
        techContent
        baseData
        salesText
        deliveryScope
        orbitVuEmbedCode
        productVideoFilenames
        productThrixtyFilenames
        documents {
          label
          fileName
          fileType
          fileSize
          link
        }
        safetyDatasheets
      }
    }
    storyblokPage: storyblokEntry(tag_list: { in: ["all_variants"] }) {
      content
    }
    group: contentServProductGroup(
      groupId: { eq: $groupId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    line: contentServProductLine(
      lineId: { eq: $lineId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    area: contentServProductArea(
      areaId: { eq: $areaId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    variants: allContentServVariant(
      filter: { parentId: { eq: $parentId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          marketingLabel
          fullSlug
          ID
          parentId
          externalKey
          properties {
            mainImage
            salesText
            deliveryScope
          }
        }
      }
    }
    crossSellingVariants: allContentServVariant(
      filter: { ID: { in: $crossSellingVariantIDs }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          externalKey
          name
          marketingLabel
          fullSlug
          properties {
            mainImage
          }
        }
      }
    }
    similarProductVariants: allContentServVariant(
      filter: {
        ID: { in: $similarProductsVariantIDs }
        locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          externalKey
          name
          marketingLabel
          fullSlug
          properties {
            mainImage
          }
        }
      }
    }
    accesoriesProductsVariants: allContentServVariant(
      filter: {
        ID: { in: $accesoriesProductsVariantIDs }
        locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          externalKey
          name
          marketingLabel
          fullSlug
          properties {
            mainImage
          }
        }
      }
    }
  }
`

export default VariantsTemplate
