import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/sharp-light-svg-icons"

import { useTranslation } from "react-i18next"

const ShowMoreLinkButton = ({ className, onStateChange = () => {} }) => {
  const [showMoreState, setShowMoreState] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    onStateChange(showMoreState)
  }, [showMoreState, onStateChange])

  return (
    <button
      className={`inline-block transition-colors duration-300 group text-ro-red hover:text-ro-red-700 active:text-ro-gray-900 focus-visible:outline-1 focus-visible:outline-offset-0 ${
        className ? className : ""
      }`}
      onClick={() => {
        setShowMoreState(showMoreState ? false : true)
      }}
    >
      <span
        className={`${
          showMoreState ? "hidden" : "inline-block"
        } underline-offset-4 group-hover:underline group-active:underline`}
      >
        {t("generic.products.show_more")}
      </span>
      <span
        className={`${
          showMoreState ? "inline-block" : "hidden"
        } underline-offset-4 group-hover:underline group-active:underline`}
      >
        {t("generic.products.show_less")}
      </span>
      <FontAwesomeIcon
        icon={faChevronDown}
        size="1x"
        className={`inline-block h-4 ml-2 -mt-0.5 align-middle transform duration-300 ${
          showMoreState ? "-rotate-180" : ""
        }`}
      />
    </button>
  )
}

export default ShowMoreLinkButton
