import React, { useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import ProductMediaInPageSwiper from "./ProductMediaInPageSwiper"
import ProductMediaModalSwiper from "./ProductMediaModalSwiper"
import ProductShoppingPanel from "./ProductShoppingPanel"
import BreadcrumbsMobileDropdown from "./BreadcrumbsMobileDropdown"
import BuyProductPanel from "./BuyProductPanel"
import ProductOrbitVuViewer from "./ProductOrbitVuViewer"
import { useTranslation } from "react-i18next"
import MemoizedComponentWrapper from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"
/* import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link" */
import { Dialog, Transition } from "@headlessui/react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faXmarkLarge } from "@fortawesome/sharp-light-svg-icons"
import ProductVariantSelector from "./ProductVariantSelector"
import { deconstructOrbitVuEmbedCode } from "../../utilities/product-data-utils"

import FluidHeadline from "../atoms/FluidHeadline"

const ProductOverview = ({
  pimUid,
  headline,
  teaser,
  variants,
  choosen_variant,
  choosen_sku,
  features,
  product_images,
  product_videos,
  product_thrixty_views,
  product_orbitvu_embed = null,
  breadcrumbs,
}) => {
  const { i18n, t } = useTranslation()
  const [showThrixtyModal, setShowThrixtyModal] = useState(false)
  const [showOrbitVuModal, setShowOrbitVuModal] = useState(false)
  const [showBuyModal, setShowBuyModal] = useState(false)
  const [buyModalStartTab, setBuyModalStartTab] = useState("dealer")
  const [showMediaSwiperModal, setShowMediaSwiperModal] = useState(false)
  const [startMediaModalSwipeIndex, setStartMediaModalSwipeIndex] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)

  //console.log("DEBUG", product_orbitvu_embed)

  const openModalHandler = (modalMode, slideIdx = 0) => {
    if (modalMode === "thrixty") {
      setShowThrixtyModal(true)
      setIsModalOpen(true)
    } else if (modalMode === "orbit-vu") {
      setShowOrbitVuModal(true)
      setIsModalOpen(true)
    } else if (modalMode === "buy") {
      window.adf && adf.ClickTrack({}, 2861695, "Button Kaufen", {})
      setShowBuyModal(true)
      setIsModalOpen(true)
    } else if (modalMode === "media-swiper") {
      setStartMediaModalSwipeIndex(slideIdx)
      setShowMediaSwiperModal(true)
      setIsModalOpen(true)
    }
  }
  const closeModalHandler = () => {
    setStartMediaModalSwipeIndex(0)
    setShowMediaSwiperModal(false)
    setShowOrbitVuModal(false)
    setShowThrixtyModal(false)
    setShowBuyModal(false)
    setIsModalOpen(false)
  }

  let relevantVideos = []

  if (product_videos && product_videos.length > 1) {
    // filter on video ending with DE, in any other language use EN
    if (i18n.language === "de") {
      relevantVideos = product_videos.filter((videoFilename) => {
        return videoFilename?.toLowerCase().split(".")[0].endsWith("de")
      })
    } else {
      relevantVideos = product_videos.filter((videoFilename) => {
        return videoFilename?.toLowerCase().split(".")[0].endsWith("en")
      })
    }
  }

  let galleryObjects = product_images.map((img, i) => {
    if (img === "undefined") {
      return { type: "noimage", url: process.env.GATSBY_IMG_PLACEHOLDER }
    } else {
      return { type: "image", url: img }
    }
  })

  // .filter((media) => media.type !== "noimage")
  // console.log("DEBUG", galleryObjects)

  // append video(s) if it fits in the total 4 items limit
  if (galleryObjects.length + relevantVideos.length <= 4) {
    galleryObjects.push(
      ...relevantVideos.map((video) => {
        return { type: "video", url: video }
      })
    )
  } else {
    // otherwise insert the videos
    relevantVideos.forEach((video, index) => {
      galleryObjects.splice(4 - relevantVideos.length, 0, {
        type: "video",
        url: video,
      })
    })
  }

  let thrixtyIframeUrl = null
  if (
    product_thrixty_views &&
    product_thrixty_views.length > 0 &&
    product_thrixty_views[0]
  ) {
    thrixtyIframeUrl = `${process.env.GATSBY_THRIXTY_IFRAME_URL}${
      product_thrixty_views[0].split(".")[0]
    }`
  }

  let orbitVuRawEmbed = null
  let orbitVuStructEmbed = {}
  if (product_orbitvu_embed) {
    orbitVuStructEmbed = deconstructOrbitVuEmbedCode(product_orbitvu_embed)
    if (orbitVuStructEmbed.scriptSrc) {
      orbitVuRawEmbed = product_orbitvu_embed
    }
  }

  let callback360Modal = () => {}
  if (orbitVuRawEmbed !== null) {
    callback360Modal = () => openModalHandler("orbit-vu")
  } else if (thrixtyIframeUrl !== null) {
    callback360Modal = () => openModalHandler("thrixty")
  }

  return (
    <>
      <BreadcrumbsMobileDropdown breadcrumbs={breadcrumbs} />
      <BlockWrapper
        block={{}}
        blockPadding="pb-12 md:pb-16 md:pt-16"
        blockWidth="container"
        showHeadline="false"
      >
        <div
          data-product-id={pimUid}
          className="flex flex-row flex-wrap -mx-grid"
        >
          <div className="relative w-full lg:w-1/2 xl:w-2/3 2xl:w-3/4 px-grid">
            <MemoizedComponentWrapper>
              <ProductMediaInPageSwiper
                open360ModalCallback={callback360Modal}
                trigger360Modal={
                  thrixtyIframeUrl !== null || orbitVuRawEmbed !== null
                }
                triggerSlideClick={true}
                onSlideClickCallback={(slideIdx) =>
                  openModalHandler("media-swiper", slideIdx)
                }
                mediaItems={galleryObjects}
              />
            </MemoizedComponentWrapper>
          </div>
          <div className="w-full pt-8 lg:w-1/2 xl:w-1/3 2xl:w-1/4 px-grid lg:pt-0">
            <FluidHeadline className="pb-2" headline={headline} tagName="h1" />
            <div className="font-bold ">{teaser}</div>
            <div className="prose max-w-none">
              <div className="mt-2">
                <ul>
                  {features?.length > 0 &&
                    features?.map((feature) => (
                      <li key={feature}>{feature}</li>
                    ))}
                </ul>
              </div>
              <AnchorLink
                offset={() => {
                  if (window && window.innerHeight && window.innerWidth) {
                    if (window.innerHeight < window.innerWidth) {
                      return (window.innerHeight / 3) * 2
                    } else {
                      return window.innerHeight / 3
                    }
                  }
                  return 0
                }}
                href="#product_details_grid"
                className="inline-block mt-4 no-underline transition-colors duration-300 text-ro-red underline-offset-4 hover:text-ro-red-700 hover:underline active:text-ro-gray-900 active:underline focus-visible:outline-1 focus-visible:outline-offset-0"
              >
                {t("generic.products.more_details")}
                <FontAwesomeIcon
                  className="h-4 ml-2"
                  icon={faArrowDown}
                  size="1x"
                />
              </AnchorLink>
            </div>
            <div className="w-full my-6 border-t md:my-8 border-ro-gray-100"></div>
            <ProductVariantSelector
              choosenMainImage={product_images[0]}
              choosenVariant={choosen_variant}
              choosenSku={choosen_sku}
              variants={variants}
            />
            <ProductShoppingPanel
              variantSku={choosen_sku}
              onLocalDealerClickCallback={() => {
                setBuyModalStartTab("dealer")
                openModalHandler("buy")
              }}
              onOnlineRetailerClickCallback={() => {
                setBuyModalStartTab("online")
                openModalHandler("buy")
              }}
            />
          </div>
        </div>
        <Transition appear show={isModalOpen} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={() => closeModalHandler()}
          >
            {showThrixtyModal && thrixtyIframeUrl && (
              <div className="fixed inset-x-0 top-0 bottom-0 flex">
                <div className="w-full h-screen bg-ro-white">
                  <div className="flex justify-center h-full">
                    <button
                      className="absolute top-0 right-0 z-20 w-12 h-12 hover:bg-ro-gray-75 active:bg-ro-gray-200"
                      onClick={() => closeModalHandler()}
                    >
                      <FontAwesomeIcon icon={faXmarkLarge} size="1x" />
                    </button>
                    <div className="h-screen aspect-[4/3]">
                      <iframe
                        title={t("generic.products.thrixty_frame_title")}
                        className="object-contain w-full"
                        style={{ aspectRatio: "4 / 3" }}
                        src={thrixtyIframeUrl}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showOrbitVuModal && product_orbitvu_embed !== null && (
              <div className="fixed inset-x-0 top-0 bottom-0 flex">
                <div className="w-full h-screen bg-ro-white">
                  <div className="flex justify-center h-full">
                    <button
                      className="absolute top-0 right-0 z-20 w-12 h-12 hover:bg-ro-gray-75 active:bg-ro-gray-200"
                      onClick={() => closeModalHandler()}
                    >
                      <FontAwesomeIcon icon={faXmarkLarge} size="1x" />
                    </button>
                    <ProductOrbitVuViewer
                      className="w-full lg:w-auto lg:h-[calc(100vh-4rem)] aspect-video lg:my-8"
                      scriptSrc={orbitVuStructEmbed.scriptSrc}
                      embedMarkup={orbitVuStructEmbed.markup}
                    />
                  </div>
                </div>
              </div>
            )}
            {showBuyModal && (
              <div className="fixed inset-x-0 top-0 bottom-0 flex">
                <div className="relative w-full h-screen bg-ro-white">
                  <button
                    className="absolute top-0 right-0 z-20 w-12 h-12 hover:bg-ro-gray-75 active:bg-ro-gray-200"
                    onClick={() => closeModalHandler()}
                  >
                    <FontAwesomeIcon icon={faXmarkLarge} size="1x" />
                  </button>
                  <div className="self-center w-full block-w-container">
                    <div className="h-[calc(100vh_-_12rem)] bg-ro-white relative mx-4">
                      <BuyProductPanel
                        sku={choosen_sku}
                        suppressNexmart={
                          process.env.GATSBY_GLOBAL_PATH_PREFIX !== "de"
                        }
                        startAtTab={buyModalStartTab}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showMediaSwiperModal && (
              <div className="fixed inset-x-0 top-0 bottom-0 flex">
                <div className="relative w-full h-screen bg-ro-white">
                  <button
                    className="absolute top-0 right-0 z-20 w-12 h-12 hover:bg-ro-gray-75 active:bg-ro-gray-200"
                    onClick={() => closeModalHandler()}
                  >
                    <FontAwesomeIcon icon={faXmarkLarge} size="1x" />
                  </button>
                  <div className="self-center w-full">
                    <div className="relative h-screen bg-ro-white">
                      <ProductMediaModalSwiper
                        mediaItems={galleryObjects}
                        startIdx={startMediaModalSwipeIndex}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        </Transition>
      </BlockWrapper>
    </>
  )
}

export default ProductOverview
