import React, { useState } from "react"
import { useTranslation } from "react-i18next"
// ---- SWIPER -----
// import Swiper core and required modules
import { Navigation, Pagination, A11y, Controller } from "swiper/modules"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

// ---- FONT-AWESOME ICONS -----
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faCube,
} from "@fortawesome/sharp-light-svg-icons"
// -------
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import CloudinaryVideoThumbnail from "../molecules/CloudinaryVideoThumbnail"
import MemoizedComponentWrapper from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"

const ASPECT_RATIO_SLIDE_IMG = "4by3"
const ASPECT_RATIO_SLIDE_MOBILE_IMG = "1by1"
const ASPECT_RATIO_THUMB_IMG = "4by3"

// design aspect rqation: 124by89
// aspect ratio: 256by167 (1280:853 => Promo-Images)
// aspect ratio: 640by401 (1280:802 => Product-Images)

const ProductMediaSwiperItem = ({
  item,
  idx,
  className = "",
  variant = "slide",
  onSlideClickCallback,
}) => {
  const variantSettings = {
    slide: {
      aspectRatioMobile: ASPECT_RATIO_SLIDE_MOBILE_IMG,
      aspectRatio: ASPECT_RATIO_SLIDE_IMG,
      aspectWrapperClass: "aspect-[1/1] md:aspect-[4/3]",
    },
    thumb: {
      aspectRatio: ASPECT_RATIO_THUMB_IMG,
      aspectWrapperClass: "aspect-[4/3]",
    },
  }

  switch (item.type) {
    case "noimage":
      return (
        <div
          className={`${className} ${variantSettings[variant].aspectWrapperClass}`}
          onClick={(e) => onSlideClickCallback(idx)}
        >
          <Image
            fillColor="ffffff"
            aspectRatio={variantSettings[variant].aspectRatio}
            className="w-full h-auto"
            image={{ filename: item.url }}
            lazyLoad={false}
          />
        </div>
      )
    case "image":
      return (
        <div
          className={`${className} ${variantSettings[variant].aspectWrapperClass}`}
          onClick={(e) => onSlideClickCallback(idx)}
        >
          <Image
            aspectRatio="1by1"
            fillColor="ffffff"
            gravity="center"
            className="w-full aspect-[1/1] md:hidden"
            image={item.url}
            lazyLoad={false}
          />
          <Image
            aspectRatio="4by3"
            fillColor="ffffff"
            gravity="center"
            className="w-full aspect-[4/3] hidden md:block"
            image={item.url}
            lazyLoad={false}
          />
        </div>
      )
    case "video":
      return (
        <div
          className={`${className} ${variantSettings[variant].aspectWrapperClass}`}
          onClick={(e) => onSlideClickCallback(idx)}
        >
          <div className="md:hidden">
            <CloudinaryVideoThumbnail
              videoFilename={item.url}
              index={idx}
              variant={variant}
              aspectRatio={variantSettings[variant].aspectRatioMobile}
            />
          </div>
          <div className="hidden md:block">
            <CloudinaryVideoThumbnail
              videoFilename={item.url}
              index={idx}
              variant={variant}
              aspectRatio={variantSettings[variant].aspectRatio}
            />
          </div>
        </div>
      )
    default:
      return null
  }
}

const ProductMediaSwiperThumbnails = ({ mediaItems, swiperInstance }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  swiperInstance.on("activeIndexChange", function (e) {
    setActiveSlideIndex(e.activeIndex)
  })

  return (
    <div className="flex-row flex-wrap hidden pt-8 -mb-8 -mx-grid md:flex">
      {mediaItems.map((item, i) => (
        <div
          key={`product-inPage-media-swiper-thumb-${i}`}
          className="w-1/4 pb-8 lg:w-1/3 2xl:w-1/6 px-grid"
        >
          <button
            className={`p-4 bg-ro-gray-50 w-full border transition-colors duration-300 hover:bg-ro-gray-75 hover:border-ro-gray-300 ${
              i === activeSlideIndex
                ? "border-ro-gray-900"
                : "border-ro-gray-200"
            }`}
            key={`main-swiper-thumb-${i}`}
            onClick={(e) => swiperInstance.slideTo(i)}
          >
            <MemoizedComponentWrapper>
              <ProductMediaSwiperItem
                variant="thumb"
                className="w-full"
                item={item}
                idx={i}
                onSlideClickCallback={() => {}}
              />
            </MemoizedComponentWrapper>
          </button>
        </div>
      ))}
    </div>
  )
}

const ProductMediaInPageSwiper = ({
  mediaItems,
  triggerSlideClick = false,
  onSlideClickCallback = () => {},
  trigger360Modal = false,
  open360ModalCallback = () => {},
}) => {
  const { t } = useTranslation()
  const [swiperInstance, setSwiperInstance] = useState(null)

  return (
    <div>
      <div className="relative -mx-4 md:mx-0">
        <button
          id={`product-inPage-media-swiper-prev`}
          className="absolute left-0 w-12 h-12 transform -translate-y-1/2 text-ro-gray-900 top-1/2 hover:bg-ro-gray-75 active:bg-ro-gray-200"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-4" size="1x" />
        </button>
        <button
          id={`product-inPage-media-swiper-next`}
          className="absolute right-0 w-12 h-12 transform -translate-y-1/2 text-ro-gray-900 top-1/2 hover:bg-ro-gray-75 active:bg-ro-gray-200"
        >
          <FontAwesomeIcon icon={faChevronRight} className="h-4" size="1x" />
        </button>
        <div className="p-16 md:border border-ro-gray-100">
          <Swiper
            modules={[Navigation, Pagination, A11y, Controller]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              renderBullet: function (index, className) {
                return `<button class="flex justify-center items-center ${className}" role="button" aria-label="Got to slide ${
                  index + 1
                }"><span class="inline-block rounded-full w-2 h-2"></span></button>`
              },
              clickable: true,
              el: `#product-inPage-media-swiper-pagination`,
              bulletClass: "w-6 h-6 custom-swiper-bullet",
              bulletActiveClass: "active",
            }}
            onSwiper={setSwiperInstance}
            navigation={{
              disabledClass: "opacity-25 pointer-events-none",
              prevEl: `#product-inPage-media-swiper-prev`,
              nextEl: `#product-inPage-media-swiper-next`,
            }}
          >
            {mediaItems.map((item, i) => (
              <SwiperSlide key={`main-swiper-inPage-slide-${i}`}>
                <ProductMediaSwiperItem
                  item={item}
                  idx={i}
                  className={triggerSlideClick ? "cursor-pointer" : ""}
                  onSlideClickCallback={
                    triggerSlideClick ? onSlideClickCallback : () => {}
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            id={`product-inPage-media-swiper-pagination`}
            className="flex justify-center h-12 items-center absolute !bottom-0 left-0 right-0"
          ></div>
          {trigger360Modal && (
            <button
              className="absolute right-0 z-10 p-5"
              onClick={(e) => open360ModalCallback()}
            >
              {t("generic.products.thrixty_link_text_short")}{" "}
              <FontAwesomeIcon icon={faCube} className="ml-1" size="1x" />
            </button>
          )}
        </div>
      </div>
      {swiperInstance !== null && (
        <ProductMediaSwiperThumbnails
          mediaItems={mediaItems}
          swiperInstance={swiperInstance}
        />
      )}
    </div>
  )
}

export default ProductMediaInPageSwiper
