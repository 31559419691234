import React from "react"

import useScript from "../../hooks/useScript"

const ProductOrbitVuViewer = ({ className, scriptSrc, embedMarkup }) => {
  const [loaded] = useScript(scriptSrc, false)

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: embedMarkup }}
    ></div>
  )
}

export default ProductOrbitVuViewer
