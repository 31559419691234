import React from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLocationDot,
  faArrowPointer,
} from "@fortawesome/sharp-light-svg-icons"

import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import useNexmartRetailers from "../../hooks/useNexmartRetailers"

const ProductShoppingPanel = ({
  variantSku,
  onLocalDealerClickCallback = () => {},
  onOnlineRetailerClickCallback = () => {},
}) => {
  const { t } = useTranslation()
  const [hasRetailers, retailers] = useNexmartRetailers(variantSku)
  const dealerLinks = process.env.GATSBY_DEALER_SEARCH_PAGE_PATH
    ? JSON.parse(process.env.GATSBY_DEALER_SEARCH_PAGE_PATH)
    : null

  if (!dealerLinks) return null

  return (
    <div>
      <Button
        className="flex items-center w-full mt-6 text-left md:mt-8"
        btnType="primary"
        onClick={() => onLocalDealerClickCallback()}
      >
        {t("generic.products.shopping_panel.buy_at_dealer")}
        <FontAwesomeIcon
          icon={faLocationDot}
          size="1x"
          className="inline-block ml-auto"
        />
      </Button>
      <div className="p-4 mt-6 border md:mt-8 border-ro-gray-100">
        <div className="flex items-start gap-2 pb-4 mb-4 border-b border-ro-gray-75">
          <div className="flex items-center justify-center flex-shrink-0 w-6 h-6">
            <FontAwesomeIcon
              icon={faArrowPointer}
              size="1x"
              className="inline-block"
            />
          </div>
          <div>
            <h5 className="font-bold">
              {t("generic.products.shopping_panel.buy_online")}
            </h5>
            <p className="mt-0.5">
              {hasRetailers !== undefined && (
                <>
                  {t("generic.products.shopping_panel.buy_online_text_first")}{" "}
                  {retailers.length === 0 ? (
                    <>
                      {t(
                        "generic.products.shopping_panel.buy_online_text_shops_zero"
                      )}
                    </>
                  ) : (
                    <button
                      className="inline underline transition-colors duration-300 underline-offset-[5px] text-ro-red hover:text-ro-red-800 active:text-ro-gray-900"
                      onClick={() => onOnlineRetailerClickCallback()}
                    >
                      {t(
                        "generic.products.shopping_panel.buy_online_text_shops",
                        {
                          count: retailers.length,
                        }
                      )}
                    </button>
                  )}{" "}
                  {t("generic.products.shopping_panel.buy_online_text_last")}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="flex items-start gap-2">
          <div className="flex items-center justify-center w-6 h-6 shrink-0">
            <FontAwesomeIcon
              icon={faLocationDot}
              size="1x"
              className="inline-block w-5"
            />
          </div>
          <div>
            <h5 className="font-bold">
              {t("generic.products.shopping_panel.find_dealer")}
            </h5>
            <p className="mt-0.5">
              {t("generic.products.shopping_panel.find_dealer_text_first")}{" "}
              <button
                className="inline underline transition-colors duration-300 underline-offset-[5px] text-ro-red hover:text-ro-red-800 active:text-ro-gray-900"
                onClick={() => onLocalDealerClickCallback()}
              >
                {t("generic.products.shopping_panel.find_dealer_text_last")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductShoppingPanel
