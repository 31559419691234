import React, { useState } from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Drawer from "./Drawer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { faCheck } from "@fortawesome/sharp-solid-svg-icons"

import FluidHeadline from "../atoms/FluidHeadline"

import MemoizedComponentWrapper from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"

const ProductVariantSelector = ({
  choosenMainImage,
  choosenVariant,
  choosenSku,
  variants,
}) => {
  const { t } = useTranslation()
  let [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <div className="mb-2 font-bold">
        {t("generic.products.choosen_variant")}
      </div>

      <button
        onClick={(e) => setIsOpen(true)}
        className="w-full p-4 text-left border bg-ro-gray-50 border-ro-gray-200 hover:bg-ro-gray-75"
      >
        <div className="flex flex-row">
          <div className="block">
            <MemoizedComponentWrapper>
              <Image
                className="w-16 h-16"
                aspectRatio="1by1"
                image={
                  choosenMainImage !== "undefined"
                    ? choosenMainImage
                    : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                }
              />
            </MemoizedComponentWrapper>
          </div>
          <div className="w-auto ml-4">
            <span className="block mb-2 font-bold">{choosenVariant}</span>
            <span className="block">
              {t("generic.products.no")} {choosenSku}
            </span>
          </div>
        </div>
      </button>

      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <FluidHeadline
          headline={t("generic.products.choose_a_variant")}
          className="mb-4"
          headlineLevel="03"
        />
        {variants?.length > 0 &&
          variants.map((item, index) => (
            <Link
              className={`block p-4 mb-4 text-left border bg-ro-gray-50 ${
                item.text === choosenSku
                  ? "border-ro-gray-900"
                  : "border-ro-gray-200"
              } hover:bg-ro-gray-75 hover:border-ro-gray-200 transition-colors duration-300`}
              key={index}
              link={item.slug}
            >
              <div className="flex flex-row">
                <div className="block">
                  <MemoizedComponentWrapper>
                    <Image
                      className="w-16 h-16"
                      aspectRatio="1by1"
                      image={
                        item.image !== "undefined"
                          ? item.image
                          : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                      }
                    />
                  </MemoizedComponentWrapper>
                </div>
                <div className="w-auto ml-4">
                  <span className="block mb-2 font-bold hyphens-auto">
                    {item.headline.replace(/,/g, ", ")}
                  </span>
                  <span className="block">
                    {t("generic.products.no")} {item.text}
                  </span>
                </div>
                <div className="pt-1 pl-4 ml-auto">
                  {item.text === choosenSku ? (
                    <span className="flex items-center justify-center w-4 h-4 p-1 rounded-full bg-ro-gray-900 text-ro-gray-50">
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="1x"
                        className="w-full h-auto"
                      />
                    </span>
                  ) : (
                    <span className="flex items-center justify-center w-4 h-4 p-1 border rounded-full border-ro-gray-900"></span>
                  )}
                </div>
              </div>
            </Link>
          ))}
      </Drawer>
    </div>
  )
}

export default ProductVariantSelector
