import React, { useState } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faChevronDown } from "@fortawesome/sharp-light-svg-icons"

import { InView } from "react-intersection-observer"

const ProductDetailsGrid = ({
  hasProductDescription,
  hasProductFunctions,
  hasDeliveryScope,
  hasTechnicalData,
  hasDownloads,
  children,
}) => {
  const { t } = useTranslation()
  const [showDropdownState, setShowDropdownState] = useState(false)
  const [activeAnchorState, setActiveAnchorState] = useState(0)

  const anchorLinks = []

  if (hasProductDescription) {
    anchorLinks.push({
      title: t("generic.products.product_description"),
      link: "#product-detail-description",
    })
  }
  if (hasProductFunctions) {
    anchorLinks.push({
      title: t("generic.products.functions_features"),
      link: "#product-functions",
    })
  }
  if (hasDeliveryScope) {
    anchorLinks.push({
      title: t("generic.products.delivery_scope"),
      link: "#delivery-scope",
    })
  }
  if (hasTechnicalData) {
    anchorLinks.push({
      title: t("generic.products.technical_data"),
      link: "#technical-data",
    })
  }
  if (hasDownloads) {
    anchorLinks.push({
      title: t("generic.products.documents_download"),
      link: "#product-downloads",
    })
  }

  return (
    <BlockWrapper blockPadding="pt-0 pb-2" block={{}}>
      <div
        id="product_details_grid"
        className="flex flex-row flex-wrap -mx-grid"
      >
        {/* Mobile trick: Width of 1 px = allows sticky behavior mobile */}
        <div className="relative w-[1px] md:w-1/3 xl:w-1/4 md:pr-4">
          <div className="sticky z-10 top-24 md:top-[9rem] md:py-14">
            <div className="relative w-[100vw] md:w-auto">
              <button
                className="flex flex-row w-full px-4 py-3 text-left border-b md:hidden bg-ro-gray-50 border-ro-gray-400 hover:bg-ro-gray-75"
                onClick={() =>
                  setShowDropdownState(showDropdownState ? false : true)
                }
              >
                {anchorLinks[activeAnchorState]?.title}
                <FontAwesomeIcon
                  className={`self-center justify-center ml-auto transition-transform duration-300 origin-center ${
                    showDropdownState ? "rotate-180" : ""
                  }`}
                  icon={faChevronDown}
                  size="1x"
                />
              </button>
              <div
                className={`absolute md:static inset-x-0 z-10 ro-search-box-shadow-mobile md:block top-full ${
                  showDropdownState ? "block" : "hidden"
                }`}
              >
                <ul>
                  {anchorLinks.map((item, index) => (
                    <li key={index}>
                      <AnchorLink
                        className={`flex flex-row w-full pl-3.5 pr-4 py-3 ${
                          index === activeAnchorState
                            ? "bg-ro-gray-100 md:border-ro-red"
                            : "bg-ro-gray-50 text-ro-gray-600"
                        } hover:bg-ro-gray-75 md:hover:bg-transparent md:bg-transparent md:border-l-2 md:border-ro-gray-100 md:hover:text-ro-gray-900 md:hover:border-ro-red`}
                        offset="90"
                        href={item.link}
                        onClick={() => setShowDropdownState(false)}
                      >
                        {item.title}
                      </AnchorLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-[calc(100%-1px)] mt-10 md:mt-0 md:w-2/3 xl:w-3/4 px-grid">
          {children
            .filter((c) => !!c)
            .map((child, index) => (
              <InView
                rootMargin="0px 0px -90% 0px"
                key={index}
                as="div"
                // threshold={0.18}
                onChange={(inView, entry) => {
                  if (entry.isIntersecting) {
                    if (index > activeAnchorState) {
                      setActiveAnchorState(index)
                    }
                  } else {
                    if (index >= activeAnchorState) {
                      if (activeAnchorState > 0) {
                        setActiveAnchorState(activeAnchorState - 1)
                      }
                    }
                  }
                }}
              >
                {child}
              </InView>
            ))}
        </div>
      </div>
    </BlockWrapper>
  )
}

export default ProductDetailsGrid
